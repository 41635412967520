

<template>
  <div class="cadastrar-clientes">

    <h1 class="title">Cadastrar Cliente</h1>

    <el-card
      :body-style="{ width: '100%', display: 'flex', 'flex-flow': 'row wrap' }"
    >
      <el-form
        ref="newClient"
        :label-position="labelPosition"
        :model="newClient"
        :rules="rules"
        status-icon
        label-width="180px"
        @submit.prevent.native="e => e.preventDefault()">

        <el-form-item
          label="Nome completo"
          prop="name"
          required>
          <el-input
            :autofocus="true"
            v-model="newClient.name" />
        </el-form-item>
        <el-form-item
          label="CPF"
          required
          prop="cpf">
          <el-input
            v-mask="'###.###.###-##'"
            v-model="newClient.cpf" />
        </el-form-item>
        <el-form-item
          label="Celular"
          prop="phone">
          <el-input
            v-mask="[ '## ####-####', '## #####-####' ]"
            id="phoneInput"
            v-model="newClient.phone"
            placeholder="11 99999-9999"/>
        </el-form-item>
        <hr><br>
        <el-form-item
          label="Limite de crédito"
          required
          prop="credit">
          <div class="el-input">
            <money
              v-model="newClient.credit"
              v-bind="money"
              class="el-input__inner" />
          </div>
        </el-form-item>
        <el-form-item
          label="Dia de vencimento"
          prop="due_day">
          <el-input
            v-mask="'##'"
            v-model="newClient.due_day" />
        </el-form-item>
        <hr><br>
        <el-row>
          <el-col :sm="12">
            <el-form-item
              label="RG"
              prop="rg">
              <el-input v-model="newClient.rg" />
            </el-form-item>
          </el-col>
          <el-col
            :offset="1"
            :sm="11">
            <el-form-item
              label="Data de Nascimento"
              prop="birthdate">
              <el-input
                v-mask="'##/##/####'"
                v-model="newClient.birthdate"
                placeholder="DD/MM/YYYY"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :sm="12">
            <el-form-item
              label="Email"
              prop="email">
              <el-input
                v-model="newClient.email"
                type="email"/>
            </el-form-item>
          </el-col>
          <el-col
            :offset="1"
            :sm="11">
            <el-form-item
              label="Sexo"
              prop="gender">
              <el-select
                v-model="newClient.gender"
                style="width: 100%;"
                placeholder="Escolha">
                <el-option
                  :value="1"
                  label="Masculino"/>
                <el-option
                  :value="2"
                  label="Feminino"/>
                <el-option
                  :value="3"
                  label="Prefere não responder"/>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col
            :sm="12">
            <el-form-item
              :required="requiredAddress"
              label="CEP"
              prop="cep">
              <el-input
                v-mask="'#####-###'"
                v-model="newClient.cep"
                @input.native="handleCEPInput" />
            </el-form-item>
          </el-col>

        </el-row>
        <el-row>
          <el-col>
            <el-form-item
              :required="requiredAddress"
              label="Logradouro"
              prop="street">
              <el-input
                :disabled="autofill.street"
                v-model="newClient.street" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :sm="12">
            <el-form-item
              :required="requiredAddress"
              label="Número"
              prop="number">
              <el-input v-model="newClient.number" />
            </el-form-item>
          </el-col>
          <el-col
            :sm="11"
            :offset="1">
            <el-form-item
              label="Complemento"
              prop="complement">
              <el-input v-model="newClient.complement" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :sm="12">
            <el-form-item
              :required="requiredAddress"
              label="Cidade"
              prop="city">
              <el-input
                :disabled="autofill.city"
                v-model="newClient.city" />
            </el-form-item>
          </el-col>
          <el-col
            :sm="11"
            :offset="1">
            <el-form-item
              :required="requiredAddress"
              label="Estado"
              prop="uf">
              <el-input
                :disabled="autofill.uf"
                v-model="newClient.uf" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin-top: 2rem;">
          <div class="form-actions">
            <el-button
              style="width: 15rem;"
              :loading="isSubmitting"
              :disabled="invalidField"
              type="primary"
              native-type="submit"
              @click="validateForm(true)">
              Salvar
            </el-button>
          </div>
        </el-row>
<!--
        <el-row style="margin-top: 2rem;">
          <p>O que deseja fazer agora?</p>
          <div style="text-align: center;">
            <el-button
              type="success"
              round
              size="small"
              @click="$router.push({ name: 'Cliente', params: { clientID: client.id } })"
            >Menu do cliente</el-button>
            <el-button
              style="margin-top: 1rem;"
              type="secondary"
              round
              size="small"
              @click="$router.push({ name: 'cadastrar-cliente' })"
            >Novo Cadastro</el-button>
          </div>
        </el-row>
-->
      </el-form>
    </el-card>

  </div>
</template>

<script>
import { Money } from "v-money";
import cep from "cep-promise";
import * as types from "@/store/types";
import phone from "@/helpers/phone";
import isCPF from "@/helpers/isCPF";
import randomCPF from "@/helpers/randomCPF";
import UpgradeSuggester from "@/components/UpgradeSuggester";
export default {
  components: {
    Money,
    UpgradeSuggester
  },
  props: {
  },
  data() {
    const uniqueProps = (rule, value, cb) => {
      if (value) {
        if (rule.field === "cpf" && !isCPF(value)) {
          this.invalidField = true;
          return cb(new Error(`${rule.field.toUpperCase()} inválido`));
        }
        try {
          this.$store.dispatch(types.AUTH_CHECK_USER, {
            [rule.field]:
              rule.field === "cpf"
                ? value.replace(/\D/gi, "")
                : rule.field === "phone"
                  ? "55" + phone.unformat(value)
                  : value
          })
          .then((response) => {
            if (response.status === 200) {
              this.invalidField = true;
              return rule.field === "phone"
                ? cb(new Error(`Celular já cadastrado`))
                : cb(new Error(`${rule.field.charAt(0).toUpperCase() + rule.field.slice(1)} já cadastrado`));
            } else {
              return cb();
            }
          })
          .catch((error) => {
            if (this.$raven && error.status === 500) {
              this.invalidField = true;
              this.$raven.captureException(error.message, { req: error });
            }
            return cb();
            //this.$notify.error({ title: error.message });
          });
          this.invalidField = false
        } catch (error) {
          return cb();
        }
      }
      if (!value && rule.field === "cpf") {
        this.invalidField = true
        return cb(new Error("CPF obrigatório"));
      }
    };
    const validateDate = (rule, value, cb) => {
      if (value) {
        const date = this.$moment(value, "DD/MM/YYYY");
        if (!date.isValid()) {
          this.invalidField = true
          return cb(new Error("Data inválida"));
        }
        if (date.isAfter(this.$moment().subtract(12, "years"))) {
          this.invalidField = true
          return cb(new Error("Usuário muito novo"));
        }
        if (date.isBefore(this.$moment().add(-150, "years"))) {
          this.invalidField = true
          return cb(new Error("Data inválida"));
        }
      }
      this.invalidField = false
      return cb();
    };
    const validateDay = (rule, value, cb) => {
      if (value) {
        const day = value;
        if (day < 1 || day > 30) {
          return cb(new Error("Dia inválido para vencimento"));
        }
      }
      return cb();
    };
    return {
      egg: new this.$egg(),
      isSubmitting: false,
      invalidField: true,
      labelPosition:
        document.documentElement.clientWidth < 720 ? "top" : "right",
      autofill: {
        uf: false,
        state: false,
        street: false
      },
      newClient: {
        name: "",
        gender: "",
        credit: "",
        due_day: 10,
        birthdate: "",
        rg: "",
        cpf: "",
        phone: "",
        email: "",
        cep: "",
        street: "",
        number: "",
        complement: "",
        city: "",
        uf: ""
      },
      rules: {
        phone: [{ validator: uniqueProps, trigger: "blur"}],
        cpf: [{ validator: uniqueProps, trigger: "blur" }],
        email: [{ validator: uniqueProps, trigger: "blur" }],
        name: [{ required: true, message: "Campo obrigatório" }],
        birthdate: [{ validator: validateDate, trigger: "blur" }],
        due_day: [{ validator: validateDay, trigger: "blur"},
                  { required: true, message: "Campo obrigatório" }],
      },
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        masked: false
      }
    };
  },
  computed: {
    requiredAddress() {
      return ["cep", "city", "uf", "street", "number"]
        .map(it => this.newClient[it])
        .some(field => !!field.length);
    }
  },
  watch: {
    requiredAddress() {
      this.$nextTick(() => {
        this.$refs.newClient.clearValidate();
      });
    }
  },
  created() {
    this.egg
      .addCode("left,down,right,r", () => {
        this.newClient.cpf = randomCPF();
      })
      .listen();
  },
  methods: {
    validateForm(keep) {
      this.$refs.newClient.validate(valid => {
        if (valid) {
          this.submitForm(keep);
        }
      });
    },
    async submitForm(keep) {
      this.isSubmitting = true;
      const payload = this.generatePayload();
      try {
        await this.$store.dispatch(types.CLIENT_REGISTER, payload);
        this.$notify.success({
          title: "Sucesso",
          message: "Cliente salvo na plataforma!"
        });
        this.isSubmitting = false;
        if (keep) {
          this.resetForm();
        } else {
          this.resetForm();
          this.show = false;
        }
      } catch(error) {
        this.isSubmitting = false;
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
    },
    resetForm(close = false) {
      this.$refs.newClient.resetFields();
      this.autofill.uf = false;
      this.autofill.street = false;
      this.autofill.city = false;
      this.show = !close;
    },
    async handleCEPInput(e) {
      const inputedCep = e.target.value.replace("-", "");
      if (inputedCep.length === 8) {
        const response = await cep(inputedCep);
        delete response.cep;
        response.uf = response.state;
        this.autofill.uf = !!response.state;
        this.autofill.street = !!response.street;
        this.autofill.city = !!response.city;
        this.newClient = { ...this.newClient, ...response };
      } else {
        ["uf", "street", "city"].forEach(prop => {
          this.autofill[prop] = false;
          this.newClient[prop] = "";
        });
      }
    },
    generatePayload() {
      return {
        user: {
          name: this.newClient.name,
          cpf: this.newClient.cpf,
          gender: this.newClient.gender,
          birthdate: this.newClient.birthdate
            ? this.$moment(this.newClient.birthdate, "DD/MM/YYYY").format(
                "YYYY-MM-DD"
              )
            : "",
          rg: this.newClient.rg,
          phone: "+55" + phone.unformat(this.newClient.phone),
          email: this.newClient.email,
          address: this.newClient.cep.replace("-", "")
            ? {
                cep: this.newClient.cep,
                street: this.newClient.street,
                number: this.newClient.number,
                complement: this.newClient.complement,
                city: this.newClient.city,
                uf: this.newClient.uf
              }
            : undefined,
          status_id: 2
        },
        store_customer: {
          total_credit: this.newClient.credit,
          due_day: +this.newClient.due_day || 10,
          status_id: 5,
          manual_register: true,
          imported: true
        }
      };
    }
  }
};
</script>
<style scoped lang="scss">
  .cadastrar-clientes {
    margin: 5rem 20rem;
    .title {
      margin-top: 1rem;
      flex: 0 100%;
      padding-top: 1rem;
      padding-bottom: 1rem;
      margin: 0;
      justify-content: center;
    }
    .form-actions {
      display: flex;
      justify-content: center;
    }
    @media (max-width:1248px) {
      margin: 2rem 10rem;
    }
    @media (max-width:960px) {
      margin: 2rem 5rem;
    }
    @media (max-width:810px) {
      margin: 2rem 3rem;
    }
  }
</style>
