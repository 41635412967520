<template>
  <section class="deal-wrapper">
    <el-card
      class="deal-details"
    >
      <h2>Detalhamento da fatura</h2>
      <el-collapse
        v-model="activeStatements"
      >
        <el-collapse-item
          v-if="user.user_statements.closed_statement"
          title="FATURA FECHADA"
          name="closed_statements"
        >
          <template slot="title">
            <section class="collapse-header">
              <span>FATURA FECHADA</span>
              <hide-at
                :breakpoints="{small: 320, medium: 1170, large: 1600}"
                breakpoint="medium"
              >
                <div>
                  <div class="wrapper">
                    <span>Data de vencimento</span>
                    <span class="content">{{ formatDate(user.user_statements.closed_statement.due_date) }}</span>
                  </div>
                  <div
                    v-if="!activeStatements.includes('closed_statements')"
                    class="wrapper"
                  >
                    <span>Total à pagar</span>
                    <span class="content">{{ formatTotal(user.user_statements.closed_statement.total_remaining_amount) }}</span>
                  </div>
                </div>
              </hide-at>
            </section>
          </template>
          <payment-selector
            :data="user.user_statements.closed_statement"
            :deselected-item="deselectedItem"
            @onClick="handleSelectorClick"
            @onClickAll="handleAll"
          />
        </el-collapse-item>
        <el-collapse-item
          v-if="user.user_statements.open_statement && user.user_statements.future_statement"
          title="PRÓXIMAS FATURAS"
          name="future_statements"
        >
          <section class="collapse-header">
            <h4 class="statement-title">FATURA EM ABERTO</h4>
            <div>
              <div class="wrapper">
                <span>Data de vencimento</span>
                <span class="content">{{ formatDate(user.user_statements.open_statement.due_date) }}</span>
              </div>
            </div>
          </section>
          <payment-selector
            :data="user.user_statements.open_statement"
            :future="true"
            :deselected-item="deselectedItem"
            @onClick="handleSelectorClick"
            @onClickAll="handleAll"
          />
          <h4 class="statement-title">DEMAIS FATURAS</h4>
          <payment-future-statements-list
            :statements="user.user_statements.future_statement.next_statements"
            :deselected-item="deselectedItem"
            @onClick="handleSelectorClick"
          />
        </el-collapse-item>
      </el-collapse>
    </el-card>
    <el-card class="deal-options">
      <div class="amount-wrapper">
        <h3>Valor a receber</h3>
        <money
          ref="input"
          v-model="totalAmountToPay"
          v-bind="$money"
          class="amount-value"
        />
      </div>
      <payment-selector-list
        :data="selectedItensToPay"
        :total="totalAmountToPay"
        :hide-total="true"
        :reverse="true"
        :action="true"
        @onClick="handleListClick"
      />
      <div
        style="margin-top: 14px;"
        class="deal-options__inputs"
      >
        <span class="input-label">Plano e parcelas
          <el-popover
            ref="plans"
            :tabindex="-1"
            placement="bottom"
            width="200"
            trigger="hover"
            content="Você poderá simular/alterar o plano e a quantidade de parcelas na próxima tela, antes de confirmar a venda."
          />
          <i
            v-popover:plans
            id="removeFocus"
            :tabindex="-1"
            class="el-icon el-icon-question plans-icon" />
        </span>

        <plans-combo
          :plans="plans"
          @onInstallmentSelect="updatePlanAndInstallments"
        />

        <span class="input-label centered">Primeira parcela
          <el-popover
            ref="plansDate"
            :tabindex="-1"
            placement="bottom"
            width="200"
            trigger="hover"
            content="Você poderá simular/alterar o plano e a quantidade de parcelas na próxima tela, antes de confirmar a venda."
          />
          <i
            v-popover:plansDate
            id="removeFocus"
            :tabindex="-1"
            class="el-icon el-icon-question plans-icon" />
        </span>
        <due-date-combo
          :user="user"
          @onDateSelect="val => first_due_date = val"
        />
        <el-alert
          v-if="dealPlan && dealPlan.down_payment > 0"
          :closable="false"
          :title="downPaymentTitle"
          type="warning"
          style="margin-bottom: 16px;"
        />
        <span class="input-label">Valor da entrada:</span>
        <div class="el-input">
          <money
            ref="input2"
            v-model="downPayment"
            v-bind="$money"
            class="el-input__inner" />
        </div>
        <span class="input-label">Desconto:</span>
        <div class="el-input">
          <money
            ref="input2"
            v-model="discount"
            v-bind="$money"
            class="el-input__inner" />
        </div>
      </div>
      <el-button
        :disabled="!isDealValid"
        class="submit-button"
        type="primary"
        round
        @click="handleSubmit">
        Continuar
      </el-button>
    </el-card>
  </section>
</template>

<script>
import { Money } from "v-money";
import { mapGetters } from "vuex";
import moment from "moment";
import { hideAt } from 'vue-breakpoints';

import * as types from "@/store/types";
import toMoney from "@/helpers/toMoney";

import PlansCombo from "@/components/PlansCombo";
import PaymentSelector from "@/components/Payment/PaymentSelector";
import PaymentSelectorList from '@/components/Payment/PaymentSelectorList';
import PaymentFutureStatementsList from "@/components/Payment/PaymentFutureStatementsList";
import DueDateCombo from "@/components/DueDateCombo";

export default {
  components: {
    Money,
    PlansCombo,
    PaymentSelector,
    PaymentSelectorList,
    PaymentFutureStatementsList,
    DueDateCombo,
    hideAt,
  },
  data() {
    return {
      activeStatements: ['closed_statements'],
      selectedItensToPay: [],
      totalAmountToPay: 0,
      discount: 0,
      deselectedItem: null,
      showDiscount: false,
      dealPlan: false,
      downPayment: 0,
      dealDownPaymentLimit: 0,
      first_due_date: '',
    };
  },
  computed: {
    ...mapGetters({
      authUser: types.AUTH_USER,
      details: types.DEAL_DETAILS,
      plans: types.DEAL_PLANS,
      user: types.CLIENT_DETAILS
    }),
    showSanitizeRegister() {
      if (this.user && this.user.neopag_status) {
        return this.user.neopag_status.id === 16;
      }
      return false;
    },
    showVerifyRegister() {
      return this.user.store_status.id === 5;
    },
    insuficientCredit() {
      return this.totalAmountToPay - this.downPayment > this.user.current_credit;
    },
    downPaymentTitle() {
      if (this.dealPlan) {
        const { down_payment } = this.dealPlan;
        const value = ((down_payment * this.totalAmountToPay) / 100);
        return `Atenção o plano selecionado precisa de no minímo ${toMoney(value)} de entrada`;
      }
      return "";
    },
    statements() {
      return this.user.user_statements;
    },
    isDealValid() {
      if (this.dealPlan) {
        const { down_payment } = this.dealPlan;
        const rule1 = this.totalAmountToPay > 0;
        const rule2 = this.downPayment >= ((down_payment * this.totalAmountToPay) / 100);

        const ruleNeopagCredit = !this.user.is_neopag_credit;

        if (this.authUser.superuser){
          return true;
        }else{
          return rule1 && rule2 && ruleNeopagCredit;
        }

      }
      return false;
    }
  },
  watch: {
    selectedItensToPay: function(newValue) {
      if (newValue.length > 0) {
        let total = 0;
        newValue.forEach(element => {
          if (element.discount > 0) {
            return total += element.total;
          }
          total += element.amount;
        });
        return this.totalAmountToPay = total;
      }
      return this.totalAmountToPay = 0;
    }
  },
  mounted() {
    this.$refs.input.$el.focus();
  },
  methods: {
    formatTotal(amount) {
      if (amount <= 0) {
        return this.formatMoney(0);
      }
      return this.formatMoney(amount);
    },
    formatDate(date) {
      return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
    },
    formatMoney(amount) {
      return toMoney(amount);
    },
    updatePlanAndInstallments(installment) {
      this.dealInstallments = installment.installment;
      this.dealDownPaymentLimit = installment.plan.down_payment;
      this.dealPlan = this.plans.find(it => it.id === installment.plan.id);
    },
    handleSelectorClick(data) {
      if (!Array.isArray(data)) {
        let selectedItensToPay = []
        const selectedIndex = this.selectedItensToPay.findIndex(item => item.id === data.id);
        if (selectedIndex === -1) {
          selectedItensToPay = selectedItensToPay.concat(this.selectedItensToPay, data);
          return this.selectedItensToPay = selectedItensToPay;
        }
        return false;
      }
      return data.forEach(item => this.handleSelectorClick(item));
    },
    handleListClick(data) {
      this.selectedItensToPay = this.selectedItensToPay.filter(item => item.id !== data.id);
      this.deselectedItem = data;
    },
    handleAll(data) {
      this.handleSelectorClick({
        statement_id: data.id,
        date: data.due_date,
        description: `Total restante (${moment(data.due_date, 'YYYY-MM-DD').format('DD/MM/YYYY')})`,
        amount: data.total_remaining_amount,
      });
    },
    async handleSubmit() {
      const interestItens = this.selectedItensToPay.filter(item => item.description === "Juros");
      let interest = 0;
      if (interestItens.length > 0) {
        interestItens.forEach(item => interest += item.amount);
      }
      await this.$store.dispatch(types.DEAL_DETAILS, {
        valid: true,
        plan: this.dealPlan,
        amount: this.totalAmountToPay,
        down_payment: this.downPayment,
        downPaymentSum: this.downPayment,
        installments: this.dealInstallments,
        first_due_date: this.first_due_date,
        payment: this.selectedItensToPay.filter(item => item.description.indexOf('Total restante') === -1),
        discount: this.discount,
        interest,
      });

      this.$router.push({ name: "Sumario do reparcelamento" });
    },
  }
};
</script>

<style lang="scss">
.collapse-header {
  display: flex;
  justify-content: space-between;
  padding-right: 14px;
  .wrapper {
    display: inline-block;
    height: 48px;
    margin: 6px 0 0 12px;
    span {
      font-family: Montserrat;
      letter-spacing: 1.025px;
      text-align: center;
      font-size: 14px;
      display: block;
      line-height: 14px;
      font-weight: 300;
    }
    .content {
      font-family: Montserrat;
      letter-spacing: 1.025px;
      color: $laranja;
      font-size: 18px;
      line-height: 22px;
      font-weight: 700;
    }
  }
}

.deal-wrapper {
  position: relative;
  margin: 0 1rem 1rem 1rem;
  h2 {
    margin-bottom: 1rem;
  }
  .selector-button-amount {
    padding: 0px 2px;
    font-size: 11px;
    height: 16px;
    position: absolute;
    top: 3px;
    width: 55px;
    right: 0px;
  }
  .el-collapse-item {
    .el-collapse-item__header {
      font-size: 14px;
      font-family: Montserrat;
      color: $preto;
      font-weight: 700;
      letter-spacing: 1.025px;
      padding: 0 4px;
    }
  }
  .amount-value {
      margin: 4px 0 1rem 0;
      background: transparent;
      border: none;
      width: 100%;
      text-align: center;
      font-size: 38px !important;
      font-family: Montserrat;
      font-weight: 700;
      border-bottom: solid 1px rgba(0, 0, 0, 0.1);
      border-radius: 0;
      color: $laranja;
      outline: none;
      padding-bottom: 4px;
    }
}

.deal-options {
  vertical-align: text-top;
  width: calc(50% - 7px);
  display: inline-block;
  margin-left: 14px;
  position: sticky;
  top: 16px;
  @media screen and (max-width: 700px) {
    width: 100%;
    display: block;
  }

  .input-label {
    display: block;
    margin-bottom: 0.25rem;
    font-size: 0.9375rem;
  }
  .el-input {
    margin-bottom: 1rem;
  }

  .el-input,
  .el-select {
    width: 100%;
    margin-bottom: 1.5rem;
  }

  .plans-icon {
    color: $laranja;
    cursor: pointer;
  }

  &__disclaimer {
    display: block;
    padding: 1rem 0 0;
    font-family: $title-font;
    line-height: 1.5;
    color: rgba($preto, 0.85);
  }

  .submit-button {
    width: 100%;
  }
}

.deal-details {
  vertical-align: text-top;
  width: calc(50% - 7px);
  display: inline-block;
  @media screen and (max-width: 700px) {
    width: 100%;
    display: block;
  }

  &__title {
    margin-bottom: 1rem;
    text-align: center;
  }

  .el-collapse-item {
    .el-collapse-item__header {
      font-size: 14px;
      font-family: Montserrat;
      color: $preto;
      font-weight: 700;
      letter-spacing: 1.025px;
      padding: 0 4px;
    }
  }
}
</style>
