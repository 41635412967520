<template>
  <cadastrar-clientes />
</template>

<script>
import CadastrarClientes from "@/containers/Clientes/CadastrarClientes";
export default {
  components: {
    CadastrarClientes
  }
}
</script>

<style>

</style>
