<template>
  <main class="sale">
    <menu-venda-recebimento />
    <section class="sale-content-container">
      <transition
        name="fade"
        mode="out-in">
        <router-view />
      </transition>
    </section>
  </main>
</template>

<script>
import MenuVendaRecebimento from "@/components/MenuVendaRecebimento";

export default {
  components: {
    MenuVendaRecebimento
  }
};
</script>

<style scoped lang="scss">
.sale {
  position: relative;
  display: flex;
  flex-flow: column wrap;
  height: 100%;
}
.sale-content-container {
  flex: 1;
  padding: 2rem 0 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
