<template>
  <el-card class="deal-receipt">
    <i class="graphic el-icon-success" />
    <h2>Reparcelamento realizado</h2>
    <h4>
      Confirmamos o reparcelamento de<br>
      <strong>{{ $n(details.amount, 'currency') }}</strong>
    </h4>
    <div class="align-center">
      <el-button
        class="submit-button"
        round
        type="primary"
        @click="newDeal">
        Novo reparcelamento
      </el-button>
      <el-button
        round
        icon="el-icon-printer"
        @click="showReceipt()"
      >Ver recibo</el-button>
    </div>
  </el-card>
</template>

<script>
import * as types from "@/store/types";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      details: types.DEAL_DETAILS,
    })
  },
  methods: {
    async newDeal() {
      await this.$store.dispatch(types.DEAL_RESET);
      await this.$store.dispatch(types.CLIENT_RESET);
      this.$router.push({ name: "Detalhes do reparcelamento" });
    },
    showReceipt() {
      this.$router.push({
        name: 'Imprimir recibo do reparcelamento'
      })
    }
  }
};
</script>

<style scoped lang="scss">
.deal-receipt {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  text-align: center;

  .graphic {
    margin-bottom: 1rem;
    font-size: 3rem;
    color: darken($verde, 10);
  }
  h2 {
    margin-bottom: 1rem;
  }
  h4 {
    font-weight: 400;
    line-height: 1.55;
  }
  .align-center {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    padding-top: 1.5rem;
    text-align: center;
    .share-button,
    .submit-button {
      flex: 0 auto;
    }
  }
}
</style>
