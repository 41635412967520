<template>
	<el-card class="resumo-venda">
		<h2>Resumo do reparcelamento</h2>
		<h3>Reparcelamento</h3>
		<div class="resumo-venda__venda">
			<div class="label">Valor</div>
			<div class="value">{{ $n(details.amount - details.discount, 'currency') }}</div>
			<div
				v-if="details.downPaymentSum > 0"
				class="label"
			>
				Entrada
			</div>
			<div
				v-if="details.downPaymentSum > 0"
				class="value"
			>
				- {{ $n(details.downPaymentSum, 'currency') }}
			</div>
			<div
				v-if="details.discount > 0"
				class="label"
			>
				Desconto
			</div>
			<div
				v-if="details.discount > 0"
				class="value"
			>
				- {{ $n(details.discount, 'currency') }}
			</div>
			<div class="label --last">Financiar</div>
			<div class="value --last">{{ $n(details.amount - details.discount - details.downPaymentSum, 'currency') }}</div>
		</div>
		<br>
		<hr>
		<h3>Financiamento</h3>
		<section class="plan-wrapper">
			<div>
				<h4>{{ details.plan.name }}</h4>
				<h4>{{ details.installments }}x</h4>
			</div>
			<div>
				<p>Entrada: <strong>{{ $n(details.downPaymentSum, "currency") }}</strong></p>
				<p>Carência: <strong>{{ details.plan.grace_period }} meses</strong></p>
				<p>Juros <strong>{{ details.plan.interest }}%</strong></p>
			</div>
		</section>
		<el-table
			:data="installments.installments"
			size="mini"
		>
			<el-table-column
				prop="number"
				label="Parcela"
				width="80px"
			/>
			<el-table-column
				:formatter="handleInstallmentsDate"
				align="center"
				label="Vencimento"
			/>
			<el-table-column
				:formatter="handleInstallmentsMoney"
				prop="amount"
				width="120"
				align="right"
				label="Valor"
			/>
		</el-table>
		<div class="resumo-venda__financiamento">
			<div class="label --last">Total:</div>
			<div class="value --bigger --last">{{ $n(installments.total_amount, 'currency') }}</div>
		</div>
		<div class="deal-buttons-wrapper">
			<el-button
				round
				@click="$router.back()"
			>
				Corrigir
			</el-button>
			<el-button
				:loading="submitting"
				:disabled="submitting || !isDealValid"
				round
				type="primary"
				@click="handleDealConfirmation"
			>
				Confirmar reparcelamento
			</el-button>
		</div>
	</el-card>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import moment from 'moment';

import toMoney from '@/helpers/toMoney';

export default {
	data() {
		return {
			submitting: false,
			hideInstallments: true,
			dealDownPaymentLimit: 0,
			activePlanID: undefined,
			activeInstallments: undefined
		};
	},
	computed: {
		...mapGetters({
			details: types.DEAL_DETAILS,
			installments: types.DEAL_INSTALLMENTS,
			plans: types.DEAL_PLANS,
			store: types.AUTH_ACTIVE_STORE,
			client: types.CLIENT_DETAILS
		}),
		firstInstallment() {
			return this.installments.installments[0];
		},
		installmentsRest() {
			const all = [...this.installments.installments];
			all.shift();
			return all;
		},
		isDealValid() {
			const rule1 = this.dealDownPaymentLimit
				? this.details.down_payment >=
					this.dealDownPaymentLimit / 100 * this.details.amount
				: true;
			return rule1;
		}
	},
	mounted() {
		this.activePlanID = this.details.plan.id;
		this.activeInstallments = this.details.installments;
	},
	methods: {
		handleInstallmentsMoney(row) {
			if (row.amount > 0) {
				return toMoney(row.amount);
			}
			return 0;
		},
		handleInstallmentsDate(row) {
			const { statement } = row;
			if (statement) {
				return moment(statement.due_date).format('DD/MM/YYYY');
			}
			return '';
		},
		generatePayload() {
			const activePlan = this.plans.find(
				it => it.id === this.activePlanID
			);

			return {
				deal: {
					amount: this.details.amount - this.details.discount,
					down_payments: [
						{
							amount: this.details.down_payment,
							payment_method: "money",
							installments: 1
						}
					],
					interest: activePlan ? activePlan.interest : 0,
					installments_number: this.activeInstallments,
				},
				payment: {
					amount: this.details.amount,
					installments: this.details.payment,
					interest: this.details.interest,
					discount: this.details.discount,
					payment_methods: [{
						amount: this.details.amount,
						payment_method: 'dinheiro',
					}],
				},
				plan_id: this.activePlanID,
				first_due_date: moment(this.details.first_due_date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
				store_customer_id: this.client.store_customer_id,
			};
		},
		// async updateSimulation(installment) {
		//   this.submitting = true;
		//   this.dealDownPaymentLimit = installment.plan.down_payment;
		//   this.activePlanID = installment.plan.id;
		//   this.activeInstallments = installment.installments;
		//   await this.$store.dispatch(types.DEAL_INSTALLMENTS, {
		//     plan_id: installment.plan.id,
		//     sale: {
		//       installments_number: installment.installments,
		//       amount: this.details.amount - this.details.down_payment,
		//       sale_date: new Date().toISOString().slice(0, 10)
		//     },
		//     store_customer_id: this.client.store_customer_id
		//   });
		//   this.submitting = false;
		// },
		async handleDealConfirmation() {
			this.submitting = true;
			try {
				await this.$store.dispatch(types.DEAL_SUBMIT, this.generatePayload());
				await this.$store.dispatch(types.CLIENT, this.client.id).catch(() => {
					this.$notify.error({
						message: "Erro ao buscar as informações atualizadas do cliente",
						title: "Ops.."
					});
				});
				this.$notify.success({
					message: "Reparcelamento realizado com successo!",
					title: "Sucesso!"
				});

				this.$router.push({ name: "Recibo do reparcelamento" });
			} catch(error) {
				if (this.$raven && error.status === 500) {
					this.$raven.captureException(error.message, { req: error });
				}
				this.$notify.error({ title: error.message });
			}

			this.submitting = false;
		}
	}
};
</script>

<style scoped lang="scss">
.plan-wrapper {
	div {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		h4 {
			font-weight: 600;
			margin: 8px 0;
		}
		p {
			font-size: 13px;
			margin-bottom: 20px;
		}
	}
}
.resumo-venda {
	width: 90%;
	max-width: 480px;
	margin: 0 auto;

	.deal-buttons-wrapper {
		margin-top: 14px;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-column-gap: 14px;
	}

	div {
		color: $preto;
	}
	h3 {
		padding-bottom: 0.25rem;
		margin-top: 1rem;
		font-weight: 500;
		color: #fd9827;
		text-transform: uppercase;
	}

	&__submit {
		width: 100%;
		margin-top: 1rem;
	}

	&__venda {
		display: flex;
		flex-flow: row wrap;
		.label,
		.value {
			flex: 0 50%;
			line-height: 1.55;
			font-size: 1rem;
			text-align: right;
			font-family: $base-font;
			&.--last {
				margin-top: 1rem;
				font-size: 1.125rem;
				font-weight: bold;
				font-style: normal;
			}
		}
		.label {
			text-align: left;
			font-style: italic;
		}
		.value {
			text-align: right;
		}
	}

	&__financiamento {
		display: flex;
		flex-flow: row wrap;
		.accordion {
			flex: 0 100%;
		}
		.label,
		.value {
			flex: 0 50%;
			line-height: 1.55;
			font-size: 1rem;
			font-family: $base-font;
			&.--last {
				margin-top: 1rem;
				font-size: 1.125rem;
				font-weight: bold;
				font-style: normal;
			}
		}
		.label {
			text-align: left;
			font-style: italic;
		}
		.value {
			text-align: right;
		}

		.installments {
			position: relative;
			flex: 0 100%;
			width: 100%;
			border-bottom: 1px dotted $preto;

			&__collapse {
				position: absolute;
				top: -27px;
				right: -24px;
				width: 28px;
				height: 28px;
				line-height: 29px;
				color: $laranja;
				text-align: center;
				cursor: pointer;
				&:hover {
					color: darken($laranja, 10);
				}
			}

			&__item {
				display: flex;
				flex-flow: row wrap;
				align-items: center;
				width: 100%;
				height: 25.8px;
				opacity: 1;
				will-change: height;
				font-style: italic;
				transition: height 0.3s ease, opacity 0.1s ease-in;

				.number {
					flex: 1;
					font-size: 1rem;
					text-align: left;
				}
				.date {
					flex: 1;
					font-size: 1rem;
					text-align: left;
				}
				.value {
					flex: 1;
					font-size: 1rem;
					text-align: right;
					font-style: normal;
				}
			}

			transition: height 0.3s ease;
			will-change: height;

			&.--collapsed {
				height: 0;
				border-bottom: none;
				.installments__item {
					height: 0;
					opacity: 0;
					transition: none;
				}
			}
		}
	}
}
</style>
